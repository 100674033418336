<template>
  <g :fill="color" fill-rule="nonzero">
    <path
      d="M6.1 13.992h10.2a.6.6 0 1 0 0-1.2H6.1a.6.6 0 1 0 0 1.2zM6.1 19.132h10.2a.6.6 0 1 0 0-1.2H6.1a.6.6 0 0 0 0 1.2z"
    />
    <path
      d="M19.7.8C20.971.8 22 1.836 22 3.113v20.558a2.306 2.306 0 0 1-2.3 2.313h-17a2.306 2.306 0 0 1-2.3-2.313V3.113A2.306 2.306 0 0 1 2.7.8h17zm0 1.2h-17c-.607 0-1.1.497-1.1 1.113v20.558c0 .617.493 1.113 1.1 1.113h17c.607 0 1.1-.496 1.1-1.113V3.113C20.8 2.497 20.307 2 19.7 2z"
    />
    <path d="M6.1 8.853h6.8a.6.6 0 0 0 0-1.2H6.1a.6.6 0 0 0 0 1.2z" />
  </g>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
